import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserAPI, updateAWSUserAPI, djangoQueryUserAPI, djangoOnboardingClientAPI } from 'APIs/user';
import { getFileURLAPI, uploadFileWithURLAPI } from 'APIs/file';
import {
    getPlansAPI,
    createPlanAPI,
    updatePlanAPI,
    deletePlanAPI,
    getIntegrationsAPI,
    createIntegrationAPI,
    updateIntegrationAPI,
    deleteIntegrationAPI
} from 'APIs/list';
import {
    getEmployeesAPI,
    createEmployeeAPI,
    getDirectReportsAPI,
    updateEmployeeAPI,
    deleteEmployeeAPI,
    getDepartmentsAPI,
    createDepartmentAPI,
    updateDepartmentAPI,
    deleteDepartmentAPI,
    createGroupEmployeesAPI,
    deleteGroupEmployeesAPI,
    getGroupEmployeesAPI,
    getGroupEmployeesAllAPI,
    getGroupEmployeesByGroupAPI,
    updateGroupEmployeesAPI
} from 'APIs/employee';
import appLogger from 'utils/common/appLogger.ts';
import { getGroupAcountList } from '../../APIs/group';

const initialState = {
    isLoggedIn: false,
    isAdmin: false,
    isInitialized: false,
    planInitialized: false,
    groupsInitialized: false,
    isLoading: false,
    onboardComplete: false,
    isSuperUser: false,
    user: {},
    org: {},
    client: {},
    planList: {},
    integrationList: {},
    planRow: {},
    onboardingStatus: {},
    userData: {},
    accountGroupData: {},
    onboardingPageIsInitialized: false,
    employees: [],
    employeeRow: {},
    directReports: [],
    departments: [],
    groupEmployeesList: [],
    qboAuthState: {
        // authenticated: true,
        // retryAuthentication: false,
        accessTokenSaved: false,
        qboRedirectPerformed: false,
        retrySendReport: false
    }
};

const NAME = 'accountSlice()';

// ==============================|| REDUX STORE: ACCOUNT DATA ||============================== //

const GET_PLANS = createAsyncThunk('plans', async () => {
    try {
        const response = await getPlansAPI();
        return response;
    } catch (error) {
        return null;
    }
});

/**
 * Create a new plan
 */
const CREATE_PLAN = createAsyncThunk('plan/create', async ({ data }) => {
    try {
        const response = await createPlanAPI({ data });
        appLogger.debug(NAME, 'CREATE_PLAN', response);
        return response;
    } catch (error) {
        appLogger.error(NAME, 'CREATE_PLAN', error);
        return null;
    }
});

/**
 * Update a plan
 */
const UPDATE_PLAN = createAsyncThunk('plan/update', async ({ id, data }) => {
    try {
        const response = await updatePlanAPI({ id, data });
        appLogger.debug(NAME, 'UPDATE_PLAN', response);
        return response;
    } catch (error) {
        appLogger.error(NAME, 'UPDATE_PLAN', error);
        return null;
    }
});

/**
 * Delete a plan
 */
const DELETE_PLAN = createAsyncThunk('plan/delete', async ({ id, data }) => {
    try {
        const response = await deletePlanAPI({ id, data });
        appLogger.debug(NAME, 'DELETE_PLAN', response);
        return response;
    } catch (error) {
        appLogger.error(NAME, 'DELETE_PLAN', error);
        return null;
    }
});

const GET_INTEGRATIONS = createAsyncThunk('integrations', async ({type=null}) => {
    try {
        const response = await getIntegrationsAPI( {type});
        return response;
    } catch (error) {
        return null;
    }
});

/**
 * Create a new integration
 */
const CREATE_INTEGRATION = createAsyncThunk('integration/create', async ({ data }) => {
    try {
        const response = await createIntegrationAPI({ data });
        appLogger.debug(NAME, 'CREATE_INTEGRATION', response);
        return response;
    } catch (error) {
        appLogger.error(NAME, 'CREATE_INTEGRATION', error);
        return null;
    }
});

/**
 * Update an integration
 */
const UPDATE_INTEGRATION = createAsyncThunk('integration/update', async ({ id, data }) => {
    try {
        const response = await updateIntegrationAPI({ id, data });
        appLogger.debug(NAME, 'UPDATE_INTEGRATION', response);
        return response;
    } catch (error) {
        appLogger.error(NAME, 'UPDATE_INTEGRATION', error);
        return null;
    }
});

/**
 * Delete an integration
 */
const DELETE_INTEGRATION = createAsyncThunk('integration/delete', async ({ id, data }) => {
    try {
        const response = await deleteIntegrationAPI({ id, data });
        appLogger.debug(NAME, 'DELETE_INTEGRATION', response);
        return response;
    } catch (error) {
        appLogger.error(NAME, 'DELETE_INTEGRATION', error);
        return null;
    }
});

const GET_USER = createAsyncThunk('account/user/get', async () => {
    // debugger; // eslint-disable-line no-debugger
    appLogger.debug(NAME, 'getUser');
    const response = await getUserAPI();
    appLogger.debug(NAME, 'getUser response', response);
    return response;
});

const UPDATE_USER = createAsyncThunk('account/user/update', async ({ data = {}, file }) => {
    // First Upload photo if necessary
    if (file) {
        const uploadURL = await getFileURLAPI({ fileName: file.name, path: 'userPhoto', mimeType: file.type });
        data.photoURL = uploadURL.url.split('?')[0];
        await uploadFileWithURLAPI({ url: uploadURL.url, file });
    }
    // Now update User data
    appLogger.debug(NAME, 'updateUser');
    const response = await updateAWSUserAPI({ data });
    appLogger.debug(NAME, 'updateUser response', response);
    return response;
});

const GET_USER_DATA = createAsyncThunk('users/', async ({ email, userId = null }) => {
    const response = await djangoQueryUserAPI({ email, userId });
    appLogger.debug(NAME, 'GET_USER_DATA', response);
    return response;
});

const setLocalSuperuser = (data) => ({
    type: 'SET_LOCAL_SUPERUSER',
    payload: data
});

const updateLocalUser = (data) => ({
    type: 'UPDATE_LOCAL_USER',
    payload: data
});

const updateQBOAuthState = (data) => ({
    type: 'UPDATE_QBO_AUTH_STATE',
    payload: data
});

const setOnboardingPageInitialized = (data) => ({
    type: 'SET_ONBOARDING_PAGE_INITIALIZED',
    payload: data
});

const ONBOARD_USER_DATA = createAsyncThunk('account/user/onboard', async ({ data = {} }) => {
    const response = await djangoOnboardingClientAPI({ data });
    return response;
});

const GET_GROUP_ACCOUNT_LIST = createAsyncThunk('user/account-list', async ({ data, params = null }) => {
    const { userType, email } = data;
    const response = await getGroupAcountList({ userType, email }, params);
    return response;
});
/* ---- Employee Management ---- */
const GET_EMPLOYEE_LIST = createAsyncThunk('employees', async ({ query }) => {
    try {
        const response = await getEmployeesAPI({ query });
        return response;
    } catch (error) {
        return null;
    }
});
const CREATE_EMPLOYEE = createAsyncThunk('employees/create', async ({ data }) => {
    try {
        const response = await createEmployeeAPI({ data });
        appLogger.debug(NAME, 'CREATE_EMPLOYEE', response);
        return response;
    } catch (error) {
        appLogger.error(NAME, 'CREATE_EMPLOYEE', error);
        return null;
    }
});
const GET_DIRECT_REPORTS = createAsyncThunk('employees/direct_reports', async ({ id }) => {
    try {
        const response = await getDirectReportsAPI({ id });
        return response;
    } catch (error) {
        return null;
    }
});
const UPDATE_EMPLOYEE = createAsyncThunk('employees/update', async ({ id, data }) => {
    try {
        const response = await updateEmployeeAPI({ id, data });
        appLogger.debug(NAME, 'UPDATE_EMPLOYEE', response);
        return response;
    } catch (error) {
        appLogger.error(NAME, 'UPDATE_EMPLOYEE', error);
        return null;
    }
});
const DELETE_EMPLOYEE = createAsyncThunk('employees/delete', async ({ id }) => {
    try {
        const response = await deleteEmployeeAPI({ id });
        appLogger.debug(NAME, 'DELETE_EMPLOYEE', response);
        return response;
    } catch (error) {
        appLogger.error(NAME, 'DELETE_EMPLOYEE', error);
        return null;
    }
});

/* ---- Department Management ---- */
const GET_DEPARTMENT_LIST = createAsyncThunk('departments', async () => {
    try {
        const response = await getDepartmentsAPI();
        return response;
    } catch (error) {
        return null;
    }
});
const CREATE_DEPARTMENT = createAsyncThunk('departments/create', async ({ data }) => {
    try {
        const response = await createDepartmentAPI({ data });
        appLogger.debug(NAME, 'CREATE_DEPARTMENT', response);
        return response;
    } catch (error) {
        appLogger.error(NAME, 'CREATE_DEPARTMENT', error);
        return null;
    }
});
const UPDATE_DEPARTMENT = createAsyncThunk('departments/update', async ({ id, data }) => {
    try {
        const response = await updateDepartmentAPI({ id, data });
        appLogger.debug(NAME, 'UPDATE_DEPARTMENT', response);
        return response;
    } catch (error) {
        appLogger.error(NAME, 'UPDATE_DEPARTMENT', error);
        return null;
    }
});
const DELETE_DEPARTMENT = createAsyncThunk('departments/delete', async ({ id }) => {
    try {
        const response = await deleteDepartmentAPI({ id });
        appLogger.debug(NAME, 'DELETE_DEPARTMENT', response);
        return response;
    } catch (error) {
        appLogger.error(NAME, 'DELETE_DEPARTMENT', error);
        return null;
    }
});

/* ---- Group Employees ---- */
const GET_GROUP_EMPLOYEES_LIST = createAsyncThunk('group-employees', async ({ id }) => {
    try {
        const response = await getGroupEmployeesAPI({ id });
        return response;
    } catch (error) {
        return null;
    }
});
const GET_GROUP_EMPLOYEES_BY_GROUP = createAsyncThunk('group-employees/group', async ({ id }) => {
    try {
        const response = await getGroupEmployeesByGroupAPI({ id });
        return response;
    } catch (error) {
        return null;
    }
});
const CREATE_GROUP_EMPLOYEES = createAsyncThunk('group-employees/create', async ({ data }) => {
    try {
        const response = await createGroupEmployeesAPI({ data });
        appLogger.debug(NAME, 'CREATE_GROUP_EMPLOYEES', response);
        return response;
    } catch (error) {
        appLogger.error(NAME, 'CREATE_GROUP_EMPLOYEES', error);
        return null;
    }
});
const UPDATE_GROUP_EMPLOYEES = createAsyncThunk('group-employees/update', async ({ id, data }) => {
    try {
        const response = await updateGroupEmployeesAPI({ id, data });
        appLogger.debug(NAME, 'UPDATE_GROUP_EMPLOYEES', response);
        return response;
    } catch (error) {
        appLogger.error(NAME, 'UPDATE_GROUP_EMPLOYEES', error);
        return null;
    }
});
const DELETE_GROUP_EMPLOYEES = createAsyncThunk('group-employees/delete', async ({ id }) => {
    try {
        const response = await deleteGroupEmployeesAPI({ id });
        appLogger.debug(NAME, 'DELETE_GROUP_EMPLOYEES', response);
        return response;
    } catch (error) {
        appLogger.error(NAME, 'DELETE_GROUP_EMPLOYEES', error);
        return null;
    }
});

const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        LOGIN(state, action) {
            // debugger; // eslint-disable-line no-debugger
            // state = { ...initialState };
            // console.log('action.payload', action.payload);
            state.isLoggedIn = true;
            state.onboardComplete = false;
            state.onboardingPageIsInitialized = false;
            state.planInitialized = false;
            state.groupsInitialized = false;
            // state.planList = {};
            state.planList = {};
            state.planRow = {};
            state.integrationList = {};
            state.user = { ...state.user, ...action.payload };
            state.qboAuthState = {
                accessTokenSaved: false,
                qboRedirectPerformed: false,
                retrySendReport: false
            };
            // state.isSuperUser = state.user.email && state.user.email === 'max@getmyfixe.com';
            state.isSuperUser = state.user.type === 'admin';
            state.isInitialized = true;
            state.accountGroupData = {};
            state.employees = [];
            state.employeeRow = {};
            state.directReports = [];
            state.departments = [];
            state.groupEmployeesList = [];
        },
        LOGOUT(state) {
            state.isLoggedIn = false;
            state.isInitialized = false;
            state.onboardComplete = false;
            state.client = {};
            state.planInitialized = false;
            state.groupsInitialized = false;
            state.planList = {};
            state.planRow = {};
            state.integrationList = {};
            state.isSuperUser = false;
            state.userData = {};
            state.onboardingPageIsInitialized = false;
            state.qboAuthState = {};
            state.accountGroupData = {};
            state.employees = [];
            state.employeeRow = {};
            state.directReports = [];
            state.departments = [];
            state.groupEmployeesList = [];
            state.user = {};
            const keepKeys = ['givenName', 'familyName', 'email'];
            if (state.user) {
                Object.keys(state.user).forEach((key) => keepKeys.includes(key) || delete state.user[key]);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GET_PLANS.fulfilled, (state, action) => {
                state.planInitialized = true;
                state.planList = [...action.payload];
            })
            .addCase(CREATE_PLAN.fulfilled, (state, action) => {
                state.planRow = action.payload;
            })
            .addCase(UPDATE_PLAN.fulfilled, (state, action) => {
                state.planRow = action.payload;
            })
            .addCase(DELETE_PLAN.fulfilled, (state, action) => {
                state.planList = action.payload;
            })
            .addCase(GET_INTEGRATIONS.fulfilled, (state, action) => {
                state.integrationList = [...action.payload];
            })
            .addCase(CREATE_INTEGRATION.fulfilled, (state, action) => {
                if (action.payload !== undefined && action.payload !== null) {
                    if (Object.keys(state.integrationList).length === 0) {
                        state.integrationList = [action.payload];
                    } else {
                        state.integrationList = [...state.integrationList, action.payload];
                    }
                }
            })
            .addCase(UPDATE_INTEGRATION.fulfilled, (state, action) => {
                state.integrationList = state.integrationList.map((item) => {
                    if (item?.id === action.payload.id) {
                        return { ...item, ...action.payload };
                    }
                    return item;
                });
            })
            .addCase(DELETE_INTEGRATION.fulfilled, (state, action) => {
                const deletedID = action.meta.arg.id;
                if (deletedID) {
                    state.integrationList = state.integrationList.filter((item) => item.id !== deletedID);
                }
            })
            /* ---- Employee Management ---- */
            .addCase(GET_EMPLOYEE_LIST.fulfilled, (state, action) => {
                if (action.payload !== undefined && action.payload !== null) {
                    state.employees = action.payload;
                }
            })
            .addCase(GET_DIRECT_REPORTS.fulfilled, (state, action) => {
                if (action.payload !== undefined && action.payload !== null) {
                    state.directReports = action.payload;
                }
            })
            .addCase(CREATE_EMPLOYEE.fulfilled, (state, action) => {
                if (action.payload !== undefined && action.payload !== null) {
                    state.employees = [...state.employees, action.payload];
                }
            })
            .addCase(UPDATE_EMPLOYEE.fulfilled, (state, action) => {
                state.employees = state.employees.map((item) => {
                    if (item?.id === action.payload.id) {
                        return { ...item, ...action.payload };
                    }
                    return item;
                });
            })
            .addCase(DELETE_EMPLOYEE.fulfilled, (state, action) => {
                const deletedID = action.meta.arg.id;
                if (deletedID) {
                    state.employees = state.employees.filter((item) => item.id !== deletedID);
                }
            })
            /* ---- Departement Management ---- */
            .addCase(GET_DEPARTMENT_LIST.fulfilled, (state, action) => {
                state.departments = action.payload;
            })
            .addCase(CREATE_DEPARTMENT.fulfilled, (state, action) => {
                if (action.payload !== undefined && action.payload !== null) {
                    state.departments = [...state.departments, action.payload];
                }
            })
            .addCase(UPDATE_DEPARTMENT.fulfilled, (state, action) => {
                state.departments = state.departments.map((item) => {
                    if (item?.id === action.payload.id) {
                        return { ...item, ...action.payload };
                    }
                    return item;
                });
            })
            .addCase(DELETE_DEPARTMENT.fulfilled, (state, action) => {
                const deletedID = action.meta.arg.id;
                if (deletedID) {
                    state.departments = state.employees.filter((item) => item.id !== deletedID);
                }
            })
            /* ---- Group Employees Management ---- */
            .addCase(GET_GROUP_EMPLOYEES_LIST.fulfilled, (state, action) => {
                if (action.payload !== undefined && action.payload !== null) {
                    state.groupEmployeesList = action.payload;
                }
            })
            .addCase(GET_GROUP_EMPLOYEES_BY_GROUP.fulfilled, (state, action) => {
                if (action.payload !== undefined && action.payload !== null) {
                    state.groupEmployeesList = action.payload;
                }
            })
            .addCase(CREATE_GROUP_EMPLOYEES.fulfilled, (state, action) => {
                if (action.payload !== undefined && action.payload !== null) {
                    state.groupEmployeesList = [...state.groupEmployeesList, action.payload];
                }
            })
            .addCase(UPDATE_GROUP_EMPLOYEES.fulfilled, (state, action) => {
                state.groupEmployeesList = state.groupEmployeesList.map((item) => {
                    if (item?.id === action.payload.id) {
                        return { ...item, ...action.payload };
                    }
                    return item;
                });
            })
            .addCase(DELETE_GROUP_EMPLOYEES.fulfilled, (state, action) => {
                const deletedID = action.meta.arg.id;
                if (deletedID) {
                    state.employees = state.groupEmployeesList.filter((item) => item.id !== deletedID);
                }
            })
            /* ---- User Management ---- */
            .addCase(GET_USER.fulfilled, (state, action) => {
                state.isInitialized = true;
                state.user = { ...state.user, ...action.payload };
            })
            .addCase(UPDATE_USER.fulfilled, (state, action) => {
                state.user = { ...state.user, ...action.payload };
            })
            .addCase(GET_USER_DATA.fulfilled, (state, action) => {
                const userData = Array.isArray(action.payload) ? action.payload?.[0] : action.payload;
                state.userData = userData;
                state.onboardComplete = !!userData?.client?.onboarding_status?.completed;
                state.isInitialized = true;
            })
            .addCase('SET_LOCAL_SUPERUSER', (state, action) => {
                state.isSuperUser = action.payload;
            })
            .addCase('UPDATE_LOCAL_USER', (state, action) => {
                state.user = { ...state.user, ...action.payload };
            })
            .addCase('SET_ONBOARDING_PAGE_INITIALIZED', (state, action) => {
                state.onboardingPageIsInitialized = action.payload;
            })
            .addCase('UPDATE_QBO_AUTH_STATE', (state, action) => {
                state.qboAuthState = { ...state.qboAuthState, ...action.payload };
            })
            .addCase(ONBOARD_USER_DATA.fulfilled, (state, action) => {
                state.userData = { ...state.userData, ...action.payload };
                // state.client = action.payload.client;
                // state.location = action.payload.location ? { ...state.location, ...action.payload.location} : state.location;
            })
            .addCase(GET_GROUP_ACCOUNT_LIST.fulfilled, (state, action) => {
                state.accountGroupData = action.payload;
                state.groupsInitialized = true;
            })
            .addMatcher(
                (action) => action.type.endsWith('/rejected'),
                (state) => {
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/fulfilled'),
                (state) => {
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/pending'),
                (state) => {
                    state.isLoading = true;
                }
            );
    }
});

const isLoading = (state) => state?.account?.isLoading;
const loginStatus = (state) => state?.account?.isLoggedIn;
const userData = (state) => state?.account?.user;
const userAPIData = (state) => state?.account?.userData;
const userClientAPIData = (state) => state?.account?.userData?.client;
const clientOnboardingStatus = (state) => state?.account?.userData?.client?.onboarding_status;
// const onboardComplete = (state) => state?.account?.onboardComplete;
const onboardComplete = (state) => state?.account?.userData?.client?.onboarding_status?.completed;
const isInitialized = (state) => state?.account?.isInitialized;
const qboAuthState = (state) => state?.account?.qboAuthState;
const planInitialized = (state) => state?.account?.planInitialized;
const accountsInitialized = (state) => state?.account?.groupsInitialized;
const planList = (state) => state?.account?.planList;
const planRow = (state) => state?.account?.planRow;
const integrationList = (state) => state?.account?.integrationList;
const groupAccountList = (state) => state?.acccount?.accountGroupData;
const employees = (state) => state?.account?.employees;
const directReports = (state) => state?.account?.directReports;
const departments = (state) => state?.account?.departments;
const groupEmployeesList = (state) => state?.account?.groupEmployeesList;

const { LOGIN, LOGOUT } = accountSlice.actions;

export {
    LOGIN,
    LOGOUT,
    GET_USER,
    UPDATE_USER,
    GET_USER_DATA,
    ONBOARD_USER_DATA,
    GET_PLANS,
    CREATE_PLAN,
    UPDATE_PLAN,
    DELETE_PLAN,
    GET_GROUP_ACCOUNT_LIST,
    GET_INTEGRATIONS,
    CREATE_INTEGRATION,
    UPDATE_INTEGRATION,
    DELETE_INTEGRATION,
    GET_EMPLOYEE_LIST,
    CREATE_EMPLOYEE,
    GET_DIRECT_REPORTS,
    UPDATE_EMPLOYEE,
    DELETE_EMPLOYEE,
    GET_DEPARTMENT_LIST,
    CREATE_DEPARTMENT,
    UPDATE_DEPARTMENT,
    DELETE_DEPARTMENT,
    GET_GROUP_EMPLOYEES_LIST,
    GET_GROUP_EMPLOYEES_BY_GROUP,
    CREATE_GROUP_EMPLOYEES,
    UPDATE_GROUP_EMPLOYEES,
    DELETE_GROUP_EMPLOYEES,
    planList,
    planRow,
    planInitialized,
    accountsInitialized,
    loginStatus,
    userData,
    isLoading,
    setLocalSuperuser,
    setOnboardingPageInitialized,
    onboardComplete,
    userAPIData,
    userClientAPIData,
    clientOnboardingStatus,
    updateLocalUser,
    isInitialized,
    updateQBOAuthState,
    qboAuthState,
    groupAccountList,
    integrationList,
    employees,
    directReports,
    departments,
    groupEmployeesList
};
export default accountSlice.reducer;
